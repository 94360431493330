import { useState } from 'react';
import { addSuccess } from '@42.nl/react-flash-messages/lib';
import { Url, urlBuilder, useQueryParams } from '@42.nl/react-url/lib';
import { makeInstance } from '@42.nl/spring-connect';
import { Button, SearchInput } from '@42.nl/ui';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Card,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap';
import { getLanguages } from '../../core/i18n';
import { AppFrame, DataTable, Loader } from '../components';
import { DeleteButton } from '../components/DeleteButton/DeleteButton';
import { Paged } from '../components/PaginationBar/Paged';
import Label from './Label';
import LabelForm, { LabelFormData } from './LabelForm';
import { LABEL_APP_URL } from './links';

async function loadLabels(queryParams: LabelListQueryParams) {
  return Label.page({
    ...queryParams
  });
}

async function loadLanguages() {
  const labelsLanguages = await Label.getLanguages();
  return labelsLanguages?.map((language) => language.toLocaleUpperCase());
}

export default function LabelList() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = useQueryParams<LabelListQueryParams>({
    location,
    defaultQueryParams: defaultLabelListQueryParams(),
    debugName: 'LabelList'
  });

  const state = useQuery(['labels', queryParams], () =>
    loadLabels(queryParams)
  );

  const languages = useQuery('languages', () => loadLanguages());

  const { t } = useTranslation(['label', 'translation']);
  const [label, setLabel] = useState<LabelFormData>();

  function changeSort(sort: string) {
    navigate(toLabelList({ ...queryParams, sort }), { replace: true });
  }

  function pageChanged(page: number, size: number) {
    navigate(toLabelList({ ...queryParams, page, size }), { replace: true });
  }

  function onSearch(text: string) {
    navigate(toLabelList({ ...queryParams, text, page: 1 }), { replace: true });
  }

  function filterChanged(name: string, value: string) {
    navigate(toLabelList({ ...queryParams, [name]: value, page: 1 }), {
      replace: true
    });
  }

  const columns = [
    {
      label: t('label:COLUMNS.KEY'),
      sort: 'key',
      width: 100,
      cell: (row: Label) => row.key
    },
    {
      label: t('label:COLUMNS.LANGUAGE'),
      sort: 'language',
      width: 30,
      filter: () => (
        <Input
          type="select"
          value={queryParams.language}
          onChange={(event) => filterChanged('language', event.target.value)}
        >
          <option value={''}>{t('label:COLUMNS.ALL_LANGUAGES')}</option>
          {languages.data?.map((language) => (
            <option
              key={language}
              value={language}
            >
              {language}
            </option>
          ))}
        </Input>
      ),
      cell: (row: Label) => row.language
    },
    {
      label: t('label:COLUMNS.VALUE'),
      sort: 'value',
      width: 100,
      cell: (row: Label) => row.value
    },
    {
      label: '',
      width: 50,
      cell: (row: Label) => (
        <div className="actions">
          <Button
            className="me-1"
            color="primary"
            onClick={() => setLabel(row)}
            icon="edit"
          />
          <DeleteButton
            name={row.key}
            onDelete={() => deleteLabel(row)}
          />
        </div>
      )
    }
  ];

  async function onSubmit(data: LabelFormData) {
    const labelToSave = makeInstance(Label, data);
    await labelToSave.save();

    addSuccess({
      text: t('translation:SAVE_SUCCESS', { key: labelToSave.key })
    });

    setLabel(undefined);
    window.location.reload();
  }

  async function deleteLabel(labelToDelete: Label) {
    await labelToDelete.remove();
    addSuccess({
      text: t('translation:DELETE_SUCCESS', { name: labelToDelete.key })
    });

    await state.refetch();
  }

  function newLabel() {
    const labelToCreate = new Label();

    if (languages.data?.length === 1) {
      labelToCreate.language = languages.data[0].toLocaleUpperCase();
    } else {
      labelToCreate.language = queryParams.language;
    }

    return labelToCreate;
  }

  return (
    <AppFrame title={t('label:LABELS')}>
      <Row className="justify-content-center">
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <Card body>
            <div className="d-flex justify-content-end mb-2">
              <Button
                color="primary"
                icon="add"
                onClick={() => setLabel(newLabel())}
              >
                {t('label:ADD_LABEL')}
              </Button>
            </div>

            <SearchInput
              id="search"
              defaultValue={queryParams.text}
              onChange={(value) => onSearch(value)}
              debounce={500}
              label={t('label:ACTIONS.SEARCH')}
              hiddenLabel={true}
            />

            {label ? (
              <Modal isOpen={!!label}>
                <ModalHeader toggle={() => setLabel(undefined)}>
                  {label.id ? t('label:CHANGE_LABEL') : t('label:ADD_LABEL')}
                </ModalHeader>
                <ModalBody>
                  <LabelForm
                    initialValues={label}
                    onCancel={() => setLabel(undefined)}
                    onSubmit={onSubmit}
                  />
                </ModalBody>
              </Modal>
            ) : undefined}

            <Loader state={state}>
              {(page) => (
                <DataTable<Label>
                  width={200}
                  columns={columns}
                  page={page}
                  sort={queryParams.sort}
                  onSort={changeSort}
                  onPage={pageChanged}
                />
              )}
            </Loader>
          </Card>
        </Col>
      </Row>
    </AppFrame>
  );
}

type LabelListQueryParams = {
  text: string;
  language: string;
} & Paged;

function defaultLabelListQueryParams(): LabelListQueryParams {
  const languages = getLanguages();
  return {
    text: '',
    language: languages.length === 1 ? languages[0].toLocaleUpperCase() : '',
    page: 1,
    size: 10,
    sort: 'key,ASC'
  };
}

function toLabelList(queryParams?: Partial<LabelListQueryParams>): Url {
  return urlBuilder({
    url: LABEL_APP_URL,
    queryParams,
    defaultQueryParams: defaultLabelListQueryParams()
  });
}
