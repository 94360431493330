import { useQuery } from 'react-query';
import { Col, Row } from 'reactstrap';
import { Loader } from '../../components';
import { Product } from '../../types/Product';
import Widget, { WidgetType } from '../Widget';
import { WidgetPanel } from '../WidgetPanel/WidgetPanel';

type Props = {
  tabId: number;
  product: Product;
  expanded: boolean;
  includeWidget?: (widget: Widget) => boolean;
};

export function WidgetDetail({
  tabId,
  product,
  expanded,
  includeWidget = () => true
}: Readonly<Props>) {
  const state = useQuery([Widget.QUERY_KEY, { tabId }], () =>
    Widget.getVisibleWidgetsForTab(tabId)
  );

  return (
    <Loader state={state}>
      {(widgets) => (
        <WidgetContainer
          widgets={widgets.filter(includeWidget)}
          product={product}
          expanded={expanded}
        />
      )}
    </Loader>
  );
}

function WidgetContainer({
  widgets,
  product,
  expanded
}: Readonly<{
  widgets: Widget[];
  product: Product;
  expanded: boolean;
}>) {
  const sidebar = widgets.some((widget) => widget.type === WidgetType.SIDEBAR);
  const size = sidebar ? 7 : 12;

  return (
    <Row className="details__content__container justify-content-between">
      <Col
        xs={12}
        lg={size}
        className="details__content__main"
      >
        {widgets
          .filter((widget) => widget.type !== WidgetType.SIDEBAR)
          .map((widget) => (
            <WidgetPanel
              key={widget.id}
              widget={widget}
              product={product}
              expanded={expanded}
            />
          ))}
      </Col>
      {sidebar ? (
        <Col
          xs={12}
          lg={12 - size}
          className="details__content__extra mt-4 mt-lg-0"
        >
          {widgets
            .filter((widget) => widget.type === WidgetType.SIDEBAR)
            .map((widget) => (
              <WidgetPanel
                key={widget.id}
                widget={widget}
                product={product}
                expanded={expanded}
              />
            ))}
        </Col>
      ) : null}
    </Row>
  );
}
