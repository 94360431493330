import { useQuery } from 'react-query';
import { Loader } from '../../components';
import Link from '../../types/Link';
import { Product } from '../../types/Product';
import Widget, { WidgetType } from '../Widget';
import { AppraisalsWidget } from './components/AppraisalsWidget';
import { ContainerWidget } from './components/ContainerWidget';
import { PrimaryWidget } from './components/PrimaryWidget';
import { SidebarWidget } from './components/SidebarWidget';
import { StructureWidget } from './components/StructureWidget';

type Props = {
  widget: Widget;
  product?: Product;
  expanded: boolean;
  expandable?: boolean;
};

async function getEntities(product: Product | undefined, entityType: string) {
  if (!product || product.productType === entityType) {
    return Promise.resolve(product ? [product] : []);
  }

  return Link.getRelated(product, entityType);
}

export function WidgetPanel({
  widget,
  product,
  expanded,
  expandable = true
}: Readonly<Props>) {
  const state = useQuery(['widget-entity', widget.id, product?.id], () =>
    getEntities(product, widget.entityType)
  );

  return (
    <Loader state={state}>
      {(entities) =>
        entities.map((entity) => (
          <WidgetRender
            key={`entity-${entity.id}`}
            widget={widget}
            product={entity}
            expanded={expanded}
            expandable={expandable}
          />
        ))
      }
    </Loader>
  );
}

export function WidgetRender({
  widget,
  product,
  expanded,
  expandable = true
}: Readonly<Props>) {
  if (!product) {
    return null;
  }

  switch (widget.type) {
    case WidgetType.APPRAISALS:
      return (
        <AppraisalsWidget
          widget={widget}
          product={product}
        />
      );
    case WidgetType.CONTAINER:
      return (
        <ContainerWidget
          widget={widget}
          product={product}
          expanded={expanded}
          expandable={expandable}
        />
      );
    case WidgetType.PRIMARY:
      return (
        <PrimaryWidget
          widget={widget}
          product={product}
        />
      );
    case WidgetType.SIDEBAR:
      return (
        <SidebarWidget
          widget={widget}
          product={product}
          expanded={expanded}
          expandable={expandable}
        />
      );
    case WidgetType.STRUCTURE:
      return (
        <StructureWidget
          widget={widget}
          product={product}
          expanded={expanded}
        />
      );
    default:
      return null;
  }
}
